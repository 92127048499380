import { Node } from "@tiptap/core";
import {
  ReactNodeViewRenderer,
  ReactRenderer,
  mergeAttributes,
} from "@tiptap/react";
import Suggestion from "@tiptap/suggestion";
import { Plugin, PluginKey } from "prosemirror-state";
import tippy from "tippy.js";
import { getPortalTarget } from "@circle-react-shared/uikit/TipTap/utilities/getPortalTarget";
import { checkIfContentMatches, insertNodeAtRange } from "../utilities";
import { EntityDisplay } from "./EntityDisplay";
import { EntityList } from "./EntityList";
import { EntityPaste } from "./EntityPaste";

export const EntitiesExtension = (portalTargetId?: string) =>
  Node.create({
    name: "entity",
    group: "inline",
    inline: true,
    selectable: false,
    atom: true,
    priority: 1100,
    addAttributes: () => ({
      sgid: {
        default: null,
        parseHTML: element => element.getAttribute("data-sgid"),
        renderHTML: attributes =>
          attributes.sgid
            ? {
                "data-sgid": attributes.sgid,
              }
            : {},
      },
    }),
    parseHTML: () => [{ tag: "entity-react" }],
    renderHTML: ({ HTMLAttributes }) => [
      "entity-react",
      mergeAttributes(HTMLAttributes),
    ],
    addNodeView() {
      return ReactNodeViewRenderer(EntityDisplay);
    },
    addProseMirrorPlugins() {
      const appendTarget = getPortalTarget(portalTargetId || null);
      const editor = this.editor;
      const name = this.name;
      return [
        new Plugin({
          key: new PluginKey("entityLinkPaste"),
          props: {
            handlePaste: (view: any, event: any) => {
              const { state } = view;
              const { selection } = state;
              const { empty } = selection;

              if (!empty) {
                return false;
              }

              const pastedData = event.clipboardData
                .getData("text/plain")
                .trim();

              const match = new RegExp(
                `^https?://${window.location.host}/c/[-\\]_.~!*'();:@&=+$,/?%#[A-z0-9]*$`,
                "g",
              ).exec(pastedData);
              if (!match) return false;

              const href = match[0];
              const range = view.state.selection.ranges[0].$from.pos;

              editor
                .chain()
                .focus()
                .insertContentAt(range, {
                  type: "text",
                  text: href,
                  marks: [
                    {
                      type: "link",
                      attrs: { href, target: "_blank" },
                    },
                  ],
                })
                .run();

              const reactRenderer = new ReactRenderer(EntityPaste, {
                props: {
                  href,
                  command: (sgid: any) => {
                    insertNodeAtRange({
                      editor,
                      range: { from: range, to: range + href.length },
                      name,
                      attrs: { sgid },
                    });

                    reactRenderer.destroy();
                  },
                },
                editor,
              });

              return true;
            },
          },
        }),
        Suggestion({
          editor: this.editor,
          char: "#",
          allowSpaces: true,
          pluginKey: new PluginKey("entity"),
          command: ({ editor, range, props }) =>
            insertNodeAtRange({ editor, range, name: this.name, attrs: props }),
          allow: ({ state, range }) =>
            checkIfContentMatches({ state, range, name: this.name }),
          render: () => {
            let reactRenderer: any;
            let popup: any;

            return {
              onStart: (props: any) => {
                reactRenderer = new ReactRenderer(EntityList, {
                  props,
                  editor: props.editor,
                });

                const getReferenceClientRect = () =>
                  props.clientRect?.() || new DOMRect(0, 0, 0, 0);

                popup = tippy("body", {
                  getReferenceClientRect,
                  appendTo: () => appendTarget,
                  content: reactRenderer.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "auto-end",
                  theme: "light-border",
                });
              },

              onUpdate(props) {
                reactRenderer.updateProps(props);

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect,
                });
              },

              onKeyDown(props) {
                if (props.event.key === "Escape") {
                  popup[0].hide();

                  return true;
                }

                return reactRenderer.ref?.onKeyDown(props);
              },

              onExit() {
                popup[0].destroy();
                reactRenderer.destroy();
              },
            };
          },
        }),
      ];
    },
  });
