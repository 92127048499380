import PropTypes from "prop-types";
import { useSortable } from "@dnd-kit/sortable";
import { useSortableListData } from "../Provider/SortableListDataProvider";
import { ListItemMemo } from "./ListItemMemo";

export const Item = ({ item, disabled = false, dataTestId = "", ...props }) => {
  const { id } = item;
  const { useAnimation } = useSortableListData();

  const {
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
    setNodeRef,
  } = useSortable({
    id,
    ...(!useAnimation && {
      transition: { duration: 0 },
    }),
    disabled,
  });

  return (
    <ListItemMemo
      ref={setNodeRef}
      item={item}
      attributes={attributes}
      listeners={listeners}
      transform={transform}
      transition={transition}
      isDragging={isDragging}
      dataTestId={dataTestId}
      {...props}
    />
  );
};

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
};
