import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const MemberCard = () => (
  <div className="skeleton-loader__member-card">
    <AvatarContainer>
      <Avatar lg />
    </AvatarContainer>
    <RowContainer>
      <Row lg />
      <Row half />
    </RowContainer>
    <Row x2l full />
  </div>
);
