import type { UserImageProps } from "@circleco/ui-kit";
import { UserImage as UiKitUserImage } from "@circleco/ui-kit";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export const UserImage = ({
  hasBorder,
  isFullRounded: isFullRoundedProp = false,
  name,
  size,
  src,
}: UserImageProps) => {
  const { isV3Enabled } = useIsV3();
  const isFullRounded = isFullRoundedProp || isV3Enabled;

  return (
    <UiKitUserImage
      hasBorder={hasBorder}
      isFullRounded={isFullRounded}
      name={name}
      size={size}
      src={src}
    />
  );
};
