import { times } from "lodash";
import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const NewEventDetail = () => (
  <div className="mx-auto max-w-5xl space-y-9 py-12">
    <div className="mb-6 flex w-full flex-col items-center space-x-6">
      <div
        id="cover-image"
        className="bg-tertiary mb-8 h-[244px] w-full shrink-0 rounded-2xl lg:h-[366px]"
      />
      <div className="!m-0 flex w-full justify-between space-x-6">
        <div className="bg-primary w-[720px] rounded-2xl p-6">
          <div className="skeleton-loader__row-container skeleton-loader__row-container--full !m-0 pb-6">
            <div className="mb-4 space-x-6 space-y-6">
              <RowContainer full className="gap-2">
                <Row xl half />
                <Row xl almostFull />
              </RowContainer>
              <div className="!mx-0 flex items-center space-x-6">
                <Row squareLarge />
                <Row md short className="!h-4" />
              </div>
            </div>
            <div className="border-primary flex justify-between space-x-6 border-t pt-6">
              <RowContainer full>
                <Row xl almostFull />
              </RowContainer>
            </div>
          </div>
          {times(2, index => (
            <div
              key={index}
              className="border-primary flex w-full items-center space-x-6 border-t py-9 pr-10"
            >
              <RowContainer full className="gap-2">
                <Row lg short className="!h-6" />
                <Row sm full />
                <Row sm almostFull />
                <Row sm full />
                <Row sm half />
                <Row sm almostFull />
                <Row sm full />
                <Row sm almostFull />
                <Row sm full />
                <Row sm half />
              </RowContainer>
            </div>
          ))}
        </div>
        <div className="hidden h-full w-[320px] flex-col space-y-6 lg:flex">
          <div className="bg-primary h-full  rounded-2xl p-6">
            <div className="border-primary flex flex-row space-x-6 border-b">
              <div className="bg-tertiary mb-4 h-[80px] w-[68px] rounded-xl" />
              <div className="mt-4 space-y-2">
                <Row md short className="!h-4" />
                <Row md short className="!h-4" />
              </div>
            </div>
            <div className="!mx-0 mb-6 mt-4 flex items-center space-x-6">
              <Row squareLarge />
              <Row md short className="!h-4" />
            </div>
            <Row x2l full />
          </div>
          <div className="bg-primary h-full space-y-6 rounded-2xl p-6">
            <div className="!mx-0 flex items-center space-x-6">
              <Row squareLarge />
              <Row md short className="!h-4" />
            </div>
            <div className="!mx-0 flex items-center space-x-6">
              <Row squareLarge />
              <Row md short className="!h-4" />
            </div>
            <div className="!mx-0 flex items-center space-x-6">
              <Row squareLarge />
              <Row md short className="!h-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
