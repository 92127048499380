import { useEffect, useMemo, useRef, useState } from "react";
import type { Editor } from "@tiptap/react";
import { t } from "i18n-js";
import { flatten, noop, uniqBy } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import Masonry from "react-responsive-masonry";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import * as giphyFetch from "@circle-react/helpers/giphyFetchHelpers";
import { activeStorageBlobUrl } from "@circle-react/helpers/urlHelpers";
import { useDirectUpload } from "@circle-react/hooks/useDirectUpload";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { SearchBox } from "@circle-react-shared/uikit/TipTapBlockEditor/GiphyPickerModal/SearchBox";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Modal } from "@circle-react-uikit/ModalV2";

interface GiphyPickerModalProps {
  editor: Editor;
  range?: { from: number; to: number };
  addToLocalInlineAttachments?: (args: any) => void;
}

export interface GiphyItem {
  id: string;
  type: string;
  title: string;
  images: {
    original: {
      url: string;
    };
    preview_gif: {
      url: string;
    };
  };
}

const hasResponseData = (
  response: any,
): response is {
  signed_id: string;
  filename: string;
  content_type: string;
} => response.signed_id && response.filename && response.content_type;

const GiphyPickerModalComponent = ({
  editor,
  range = { from: 0, to: 0 },
  addToLocalInlineAttachments = noop,
}: GiphyPickerModalProps) => {
  const modal = useModal();
  const LIMIT = 10;
  const [query, setQuery] = useState<string>("");
  const [isPreparing, setIsPreparing] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const fetchGifs = ({ pageParam = 1 }) =>
    query
      ? giphyFetch.search(query, { offset: pageParam, limit: LIMIT })
      : giphyFetch.trending({ offset: pageParam, limit: LIMIT });

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ["chat-members-search", query],
    fetchGifs,
    {
      getNextPageParam: (lastPage: {
        data: GiphyItem[];
        pagination: { offset: number; count: number; total_count: number };
      }) => {
        const loadedItems =
          lastPage.pagination.offset + lastPage.pagination.count;
        const totalItems = lastPage.pagination.total_count;
        return loadedItems < totalItems
          ? lastPage.pagination.offset + LIMIT
          : null;
      },
    },
  );

  const gifs = useMemo(
    () => uniqBy(flatten(data?.pages?.map(page => page.data)), "id"),
    [data],
  );

  const { uploadFile, status, isError, response, progress } = useDirectUpload();
  const isUploading = !isError && status === "uploading";
  const isProcessing = isUploading || isPreparing;

  const createImageNode = () => {
    if (!hasResponseData(response)) return;
    const { signed_id, filename, content_type } = response;
    const url = activeStorageBlobUrl({
      signed_id,
      filename,
    });
    addToLocalInlineAttachments({ url, signed_id, content_type });
    editor
      .chain()
      .focus()
      .insertContentAt(range, [
        {
          type: "image",
          attrs: { url, signed_id, content_type, width_ratio: 1 },
        },
        {
          type: "hardBreak",
        },
      ])
      .run();
    modal.remove();
  };

  const addGif = async (gif: GiphyItem) => {
    setIsPreparing(true);
    const response = await fetch(gif.images.original.url);
    const contentType = gif.type == "video" ? "video/mp4" : "image/gif";
    const file = new File(
      [await response.blob()],
      `${gif.id}.${contentType.split("/")[1]}`,
      {
        type: contentType,
      },
    );
    uploadFile(file);
  };

  useEffect(() => {
    if (status === "uploading") {
      setIsPreparing(false);
    }
    if (status === "completed" && response) {
      createImageNode();
    }
  }, [status, response]);

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.remove}
      initialFocus={inputRef}
    >
      <Modal.Overlay />
      <Modal.Content size="lg">
        <Modal.Header>
          <Modal.Title size="sm">{t("tiptap.add_gif")}</Modal.Title>
          <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
            <Modal.CloseButton onClick={modal.remove} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          {isProcessing ? (
            <div className="bg-primary  pointer-events-none flex min-h-[240px] w-full flex-col items-center justify-center py-6">
              <div className="mb-4 flex flex-col items-center">
                <Loader center />
                {progress ? (
                  <Typography.LabelSm weight="medium">
                    {t("tiptap.progress", {
                      progress: progress.toFixed(0),
                    })}
                  </Typography.LabelSm>
                ) : (
                  <Typography.LabelSm weight="medium">
                    {t("tiptap.preparing")}
                  </Typography.LabelSm>
                )}
              </div>
            </div>
          ) : (
            <div className="bg-primary border-primary flex w-full flex-col space-y-1">
              <SearchBox ref={inputRef} query={query} setQuery={setQuery} />
              {isLoading && <LoaderRow />}
              {Boolean(gifs.length) && (
                <InfiniteScroll
                  dataLength={gifs.length}
                  next={fetchNextPage}
                  hasMore={!!hasNextPage}
                  scrollThreshold={0.6}
                  height={360}
                  loader={
                    <div className="col-span-3 flex justify-center py-3">
                      <LoaderRow />
                    </div>
                  }
                  className="mb-2 py-2"
                >
                  <Masonry columnsCount={3} gutter="8">
                    {gifs.map(gif => (
                      <button
                        type="submit"
                        key={gif.id}
                        onClick={() => addGif(gif)}
                        aria-label={gif.title}
                      >
                        <img
                          loading="lazy"
                          alt=""
                          className="rounded"
                          width={180}
                          src={gif.images.preview_gif.url}
                        />
                      </button>
                    ))}
                  </Masonry>
                </InfiniteScroll>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const GiphyPickerModal = ModalManager.create(
  ({ editor, range, addToLocalInlineAttachments }: GiphyPickerModalProps) => (
    <GiphyPickerModalComponent
      editor={editor}
      range={range}
      addToLocalInlineAttachments={addToLocalInlineAttachments}
    />
  ),
);

export const useGiphyPickerModal = () => useModal(GiphyPickerModal);
