import PropTypes from "prop-types";
import classNames from "classnames";
import "./styles.scss";

export const Panel = ({
  children,
  overflowVisible = true,
  withBorder = true,
  className,
}) => (
  <div
    className={classNames(
      "content-panel",
      {
        "overflow-hidden": !overflowVisible,
      },
      className,
    )}
  >
    <div
      className={classNames(
        "content-panel__container !bg-primary !shadow-none",
        {
          "border-primary border": withBorder,
        },
      )}
    >
      {children}
    </div>
  </div>
);

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  overflowVisible: PropTypes.bool,
  withBorder: PropTypes.bool,
  className: PropTypes.string,
};
