import classNames from "classnames";
import { times, uniqueId } from "lodash";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export interface CommunitySidebarV2Props {
  count?: number;
}

export const CommunitySidebarSkeleton = ({
  count = 3,
}: CommunitySidebarV2Props) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames(
        "relative grid h-[calc(100vh-65px)] grid-rows-[1fr]",
        {
          "left-0": !isV3Enabled,
          "left-16": isV3Enabled,
        },
      )}
    >
      <div className="h-full max-w-[18.5rem] overflow-hidden pb-0 pl-0">
        <div className="flex h-16 min-h-[4rem] items-center px-4">
          <Row xl long />
        </div>
        <div className="my-4">
          <RowContainer xl full>
            <Row almostFull className="ml-4" />
            <Row almostFull className="ml-4" />
            <Row almostFull className="ml-4" />
            <Row almostFull className="ml-4" />
            <Row almostFull className="ml-4" />
            <Row almostFull className="ml-4" />
          </RowContainer>
        </div>
        {times(count, () => (
          <div key={uniqueId()} className="my-5 items-center px-4">
            <RowContainer xl>
              <Row half lg />
              <Row almostFull className="ml-4" />
              <Row almostFull className="ml-4" />
              <Row almostFull className="mb-2 ml-4" />
            </RowContainer>
          </div>
        ))}
      </div>
    </div>
  );
};
