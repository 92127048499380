import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

const renderAvatarContainer = index => (
  <div key={index} className="p-5">
    <AvatarContainer md full>
      <Avatar />
      <RowContainer full>
        <Row lg />
        <Row lg full />
      </RowContainer>
    </AvatarContainer>
  </div>
);

export const MessagingListPage = () => (
  <div className="p-6">
    <RowContainer lg full>
      <div className="bg-primary border-primary mb-14 divide-y rounded-lg border">
        {Array.from(Array(4).keys()).map(renderAvatarContainer)}
      </div>
    </RowContainer>
    <RowContainer lg full>
      <div className="bg-primary border-primary mb-14 divide-y rounded-lg border">
        {Array.from(Array(3).keys()).map(renderAvatarContainer)}
      </div>
    </RowContainer>
  </div>
);
