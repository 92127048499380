import { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { mediaTranscodingsApi } from "@circle-react/api/mediaTranscodingsApi";
import { useUppyUpload } from "@circle-react/components/Spaces/CourseSpace/Lessons/Edit/Content/LessonContent/useUppyUpload";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { usePunditUserContext } from "@circle-react/contexts";
import { activeStorageBlobUrl } from "@circle-react/helpers/urlHelpers";
import { useMediaTranscoding } from "@circle-react/hooks/useMediaTranscoding";
import type { MediaTranscoding } from "@circle-react/types/MediaTranscoding";
import { contentTypeParser } from "@circle-react-shared/uikit/TipTap/utilities/contentTypeParser";

interface UploadedFile {
  signed_id: string;
  filename: string;
  content_type: string;
  url: string;
  [key: string]: any;
}

interface UploadedMediaFile extends UploadedFile {
  media_transcoding: MediaTranscoding;
  video_variants: {
    original: string;
    hls: string | null;
  };
}

type UploadStatus =
  | "idle"
  | "preparing"
  | "uploading"
  | "processing"
  | "succeeded"
  | "failed";

type UploadError = "upload_failed" | "processing_failed";

interface FileUploadOptions {
  onComplete: (file: UploadedFile | UploadedMediaFile) => void;
}

export const useFileUpload = ({ onComplete }: FileUploadOptions) => {
  const [status, setStatus] = useState<UploadStatus>("idle");
  const [error, setError] = useState<UploadError | null>(null);
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const { currentCommunitySettings } = usePunditUserContext();
  const { tiptap_video_transcoding_enabled: isTiptapVideoTranscodingEnabled } =
    currentCommunitySettings || {};

  const { mutate: startTranscodingMedia } = useMutation<
    MediaTranscoding,
    ApiError,
    UploadedFile
  >(file => mediaTranscodingsApi.create({ blobSignedId: file.signed_id }), {
    onError: () => {
      setError("processing_failed");
      setStatus("failed");
    },
  });

  const { uppy, progress } = useUppyUpload({
    onUploadStarted: () => setStatus("uploading"),
    onUploadComplete: (file: UploadedFile) => {
      setUploadedFile(file);

      const isTranscodableMedia =
        isTiptapVideoTranscodingEnabled &&
        contentTypeParser.isVideo(file.content_type) &&
        !contentTypeParser.isNativelySupportedVideo(file.content_type);

      if (isTranscodableMedia) {
        setStatus("processing");
        startTranscodingMedia(file);
      } else {
        const { signed_id, filename } = file;
        const url = activeStorageBlobUrl({ signed_id, filename });

        onComplete({ ...file, url });
        setStatus("succeeded");
      }
    },
    onUploadFailed: () => {
      setError("upload_failed");
      setStatus("failed");
    },
  });

  useMediaTranscoding({
    enabled: status === "processing" && Boolean(uploadedFile?.signed_id),
    signedId: uploadedFile?.signed_id,
    onSuccess: hlsUrl => {
      if (!uploadedFile) {
        return;
      }

      const { signed_id, filename } = uploadedFile;
      const url = activeStorageBlobUrl({ signed_id, filename });

      onComplete({
        ...uploadedFile,
        url,
        media_transcoding: { status: "succeeded" },
        video_variants: { original: url, hls: hlsUrl },
      });
      setStatus("succeeded");
    },
    onError: () => {
      setError("processing_failed");
      setStatus("failed");
    },
  });

  const addFile = useCallback(
    file => {
      setStatus("preparing");
      setError(null);
      setUploadedFile(null);

      uppy.addFile(file);
    },
    [uppy],
  );

  const cancelUpload = useCallback(() => {
    setStatus("idle");
    setError(null);
    setUploadedFile(null);

    uppy.cancelAll();
  }, [uppy]);

  return {
    status,
    progress,
    error,
    addFile,
    cancelUpload,
  };
};
