import PropTypes from "prop-types";
import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";
import { MembersAvatarRow } from "../MembersAvatarRow";

export const MainHeader = () => (
  <>
    <div className="skeleton-loader skeleton-loader--main-header-desktop">
      <RowContainer inline full>
        <Row squareLarge />
        <Row xxl />
      </RowContainer>
      <RowContainer inline full>
        <Row x2l full />
        <Row squareExtraLarge />
      </RowContainer>
      <div className="skeleton-loader--main-header-desktop__actions">
        <RowContainer inline full>
          <Row square />
          <Row square />
          <Row square />
          <Row square />
          <Row xxl short />
          <Avatar />
        </RowContainer>
      </div>
    </div>
    <div className="skeleton-loader skeleton-loader--main-header-mobile">
      <RowContainer>
        <Row square />
      </RowContainer>
      <div className="skeleton-loader--main-header-mobile__logo">
        <RowContainer inline full>
          <Row squareLarge />
          <Row xxl large />
        </RowContainer>
      </div>
      <AvatarContainer>
        <Avatar sm />
      </AvatarContainer>
    </div>
  </>
);

export const MainHeaderV2 = ({ showMemberAvatarsRow }) => (
  <>
    <div className="hidden h-16 items-center px-4 md:flex">
      <div
        style={{
          flex: "0 0 308px",
        }}
      >
        <RowContainer inline full>
          <Row xxl />
        </RowContainer>
      </div>
      <RowContainer inline full>
        <Row squareLarge />
        <Row lg half />
      </RowContainer>
      <div className="skeleton-loader--main-header-desktop__actions">
        <RowContainer inline full>
          <div className="mr-4 flex space-x-4">
            <Row square />
            <Row square />
            <Row square />
            {showMemberAvatarsRow && <MembersAvatarRow />}
          </div>
          <Row x2l long />
        </RowContainer>
      </div>
    </div>
    <div className="skeleton-loader skeleton-loader--main-header-mobile">
      <RowContainer>
        <Row square />
      </RowContainer>
      <div className="skeleton-loader--main-header-mobile__logo">
        <RowContainer inline full>
          <Row xxl large />
        </RowContainer>
      </div>
      <AvatarContainer>
        <Avatar sm />
      </AvatarContainer>
    </div>
  </>
);

MainHeaderV2.propTypes = {
  showMemberAvatarsRow: PropTypes.bool,
};
