import PropTypes from "prop-types";
import {
  DndContext as DndKitContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { useSortableListData } from "./SortableListDataProvider";

export const DndContext = ({ children, shouldUseKeyboardSensor }) => {
  const {
    lockedVerticalAxis,
    lockedHorizontalAxis,
    onDragStart,
    onDragEnd,
    onDragOver,
    distance,
  } = useSortableListData();

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: distance,
    },
  });

  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });

  const sensorArgs = [pointerSensor];
  if (shouldUseKeyboardSensor) {
    sensorArgs.push(keyboardSensor);
  }

  const sensors = useSensors(...sensorArgs);

  const modifiers = [
    restrictToWindowEdges,
    ...(lockedVerticalAxis ? [restrictToVerticalAxis] : []),
    ...(lockedHorizontalAxis ? [restrictToHorizontalAxis] : []),
  ];

  return (
    <DndKitContext
      sensors={sensors}
      modifiers={modifiers}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      {children}
    </DndKitContext>
  );
};

DndContext.propTypes = {
  children: PropTypes.node.isRequired,
};
