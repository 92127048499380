import PropTypes from "prop-types";
import { times } from "lodash";
import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";
import { MembersAvatarRow } from "../MembersAvatarRow";

export const EventsListView = () => (
  <div className="space-y-12">
    <ListViewBlock count={5} />
    <ListViewBlock count={3} />
  </div>
);

const ListViewBlock = ({ count = 5 }) => (
  <div className="border-primary bg-primary w-full rounded-md border p-2">
    {times(count, index => (
      <div key={index} className="flex p-4">
        <AvatarContainer md full>
          <Avatar />
          <RowContainer full>
            <div className="flex justify-between">
              <RowContainer full>
                <Row md half />
                <Row sm />
              </RowContainer>
              <div className="mr-4 flex items-center space-x-8">
                <MembersAvatarRow />
                <Row lg className="!h-6" />
              </div>
            </div>
          </RowContainer>
        </AvatarContainer>
      </div>
    ))}
  </div>
);

ListViewBlock.propTypes = {
  count: PropTypes.number,
};
