import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const EventItem = ({ isMobileView = false }) => (
  <div className="w-full">
    <RowContainer full>
      <Row lg short className="!h-6" isDark />
    </RowContainer>
    {isMobileView ? (
      <div className="mt-4 flex flex-col justify-start gap-y-3">
        <Row md almostFull isDark />
        <Row md long className="!w-32" isDark />
      </div>
    ) : (
      <div className="my-4 flex flex-col justify-start gap-y-3">
        <Row md almostFull isDark />
      </div>
    )}
  </div>
);
