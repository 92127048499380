import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { arrayMove } from "@dnd-kit/sortable";
import { isFunction, noop } from "lodash";

const SortableListData = createContext({
  items: [],
  activeItem: {},
  setActiveItem: noop,
  onSort: noop,
  useDragHandle: false,
  lockedVerticalAxis: false,
  lockedHorizontalAxis: false,
  useAnimation: false,
  distance: 0,
});
SortableListData.displayName = "SortableListData";

export const useSortableListData = () => useContext(SortableListData);

export const SortableListDataProvider = ({
  items,
  onSort,
  useDragHandle = false,
  lockedVerticalAxis = false,
  lockedHorizontalAxis = false,
  useAnimation = true,
  distance = 0,
  children,
  onDragStart,
  onDragEnd,
  onDragOver,
}) => {
  const [activeItem, setActiveItem] = useState();

  const handleDragStart = event => {
    const item = items.find(({ id }) => id === event.active.id);
    setActiveItem(item);
  };

  const defaultDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over.id);
      const newItems = arrayMove(items, oldIndex, newIndex);
      onSort(newItems);
    }
  };

  const handleDragEnd = event => {
    setActiveItem(null);
    return isFunction(onDragEnd) ? onDragEnd(event) : defaultDragEnd(event);
  };

  return (
    <SortableListData.Provider
      value={{
        items,
        activeItem,
        setActiveItem,
        onDragStart: isFunction(onDragStart) ? onDragStart : handleDragStart,
        onDragEnd: handleDragEnd,
        onDragOver,
        onSort,
        useDragHandle,
        lockedVerticalAxis,
        lockedHorizontalAxis,
        useAnimation,
        distance,
      }}
    >
      {children}
    </SortableListData.Provider>
  );
};

SortableListDataProvider.propTypes = {
  items: PropTypes.array,
  onSort: PropTypes.func,
  useDragHandle: PropTypes.bool,
  lockedVerticalAxis: PropTypes.bool,
  lockedHorizontalAxis: PropTypes.bool,
  useAnimation: PropTypes.bool,
  children: PropTypes.node.isRequired,
  distance: PropTypes.number,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragOver: PropTypes.func,
};
