import PropTypes from "prop-types";
import classnames from "classnames";
import { useBEMClasses } from "@circle-react/hooks/utils/useBEMClasses";

export const Avatar = props => {
  const blockElement = "skeleton-loader__avatar";
  const { modifiers } = useBEMClasses(blockElement, props);

  return (
    <div className={classnames(blockElement, modifiers)}>
      <div className="skeleton-loader__avatar-content !bg-tertiary rounded-md"></div>
    </div>
  );
};

Avatar.propTypes = {
  sm: PropTypes.bool,
  lg: PropTypes.bool,
};
