import PropTypes from "prop-types";
import { times, uniqueId } from "lodash";
import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const ListView = ({ count = 1 }) => (
  <div className="skeleton-loader skeleton-loader--list-view">
    {times(count, () => (
      <div
        key={uniqueId()}
        className="skeleton-loader--list-view__item !bg-primary !border-primary"
      >
        <AvatarContainer md full>
          <Avatar />
          <RowContainer full>
            <div className="flex justify-between">
              <RowContainer full>
                <Row md half />
                <Row sm />
              </RowContainer>
              <div className="mr-4 flex items-center space-x-4">
                <Row square lg />
                <Row square lg />
              </div>
            </div>
          </RowContainer>
        </AvatarContainer>
      </div>
    ))}
  </div>
);

ListView.propTypes = {
  count: PropTypes.number,
};
