import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { Tab as HeadlessUITab } from "@headlessui/react";
import classNames from "classnames";

interface TabProps extends ComponentPropsWithoutRef<"button"> {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
}

export const TabRoot = ({
  children,
  className = "",
  disabled = false,
  ...props
}: TabProps) => (
  <HeadlessUITab as="div" className="flex" data-testid="tab-div-wrapper">
    {({ selected = false }) => (
      <button
        type="submit"
        data-testid="tab-button"
        disabled={disabled}
        className={classNames(
          "inline-block h-10 whitespace-nowrap border-b-2 py-2 text-base leading-none",
          {
            "cursor-not-allowed": disabled,
            "cursor-pointer": !disabled,
            "border-darkest": selected,
            "hover:border-primary border-transparent transition-colors":
              !selected,
          },
          className,
        )}
        {...props}
      >
        {/*
            Render the children again on a hidden span block with bold styles,
            so that the button minimum width is the same as the bold text width,
            preventing layout jumps on the X axis when the selected tab changes.
          */}
        <span
          className="invisible block h-0 overflow-hidden font-semibold"
          aria-hidden
        >
          {children}
        </span>
        <span
          className={classNames("block", {
            "text-dark font-semibold": selected,
            "text-light font-normal": !selected,
          })}
        >
          {children}
        </span>
      </button>
    )}
  </HeadlessUITab>
);
