import { times } from "lodash";
import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const NewEventsThumbnailView = ({
  count = 6,
  disableCoverImage = false,
}) => (
  <div className="mx-6 max-w-2xl space-y-9 pb-12 md:mx-auto">
    {!disableCoverImage && (
      <div className="mb-6 flex w-full flex-col items-center space-x-6">
        <div
          id="cover-image"
          className="bg-overlay-dark mb-8 h-[244px] w-full shrink-0 rounded-md"
        />
        <div className="skeleton-loader__row-container skeleton-loader__row-container--full !m-0">
          <div className="mb-4 flex justify-between space-x-6">
            <RowContainer full>
              <Row xl half isDark />
              <Row xl almostFull isDark />
            </RowContainer>
            <div className="mr-4">
              <Row lg short className="!h-6" isDark />
            </div>
          </div>
          <div className="flex justify-between space-x-6">
            <RowContainer full>
              <Row md half isDark />
              <Row md almostFull isDark />
              <Row md half isDark />
            </RowContainer>
          </div>
          <div className="flex space-x-4 pt-6">
            <div className="bg-overlay-dark h-8 w-32 rounded-full" />
            <div className="bg-overlay-dark h-8 w-32 rounded-full" />
            <div className="bg-overlay-dark h-8 w-32 rounded-full" />
          </div>
        </div>
      </div>
    )}

    {times(count, index => (
      <div key={index} className="flex h-[100px] w-full items-center space-x-6">
        <div className="bg-overlay-dark h-[110px] w-[220px] shrink-0 rounded-md" />
        <RowContainer full>
          <div className="flex justify-between space-x-6">
            <RowContainer full>
              <Row md full isDark />
              <Row md almostFull isDark />
            </RowContainer>
            <div className="mr-4">
              <Row lg short className="!h-6" isDark />
            </div>
          </div>
        </RowContainer>
      </div>
    ))}
  </div>
);
