import { t } from "i18n-js";
import { Typography } from "@circle-react/components/shared/uikit/Typography";

export const EmptyState = ({ isLoading, query }: any) => (
  <div className="-mt-4 px-6 py-3">
    <Typography.LabelSm color="text-light">
      {isLoading
        ? t("tiptap.extensions.entities.loading")
        : query
        ? t("tiptap.extensions.entities.no_results")
        : t("tiptap.extensions.entities.start_typing_to_search")}
    </Typography.LabelSm>
  </div>
);
