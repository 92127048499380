import type { RichTextOembed } from "@circle-react/types";

type EmbedSize = "small" | "large" | "video" | "aspect-ratio";

// Embed size overrides that improve previews
const EMBED_SIZES: Record<string, EmbedSize> = {
  SoundCloud: "small",
  GitHub: "large",
  Brightcove: "video",
  Panopto: "video",
  Loom: "video",
  Vimeo: "video",
  "Wistia, Inc.": "video",
  YouTube: "video",
  Arcade: "aspect-ratio",
  Canva: "aspect-ratio",
  Genially: "aspect-ratio",
};

// We're using the height value returned by Embedly to minimize content
// cutoff. It's not ideal, but appears to give the best overall results
// without adding automatic iframe resizing.
//
// We're explicitly adjusting the iframe size with EMBED_SIZES to handle cases
// when Embedly returns inaccurate height values for embeds, e.g., Loom and
// Vimeo, and to avoid stretched videos when rendering 16/9 videos on small
// screens.
//
// TODO: Add support for manual iframe resizing or add a script that handles
// automatic resizing.
export const getIframeSizeProps = ({ embed }: { embed: RichTextOembed }) => {
  const embedSize = embed.provider_name
    ? EMBED_SIZES[embed.provider_name]
    : null;

  if (embedSize === "small") {
    return { height: 160 };
  }

  if (embedSize === "large") {
    return { height: 384 };
  }

  if (embedSize === "video") {
    return { style: { aspectRatio: "16 / 9", height: "auto" } };
  }

  if (embedSize === "aspect-ratio" && embed.height && embed.width) {
    return {
      style: {
        aspectRatio: `${embed.width} / ${embed.height}`,
        height: "auto",
      },
    };
  }

  return embed.height ? { height: embed.height } : {};
};
