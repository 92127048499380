import { memo, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import "fullscreen-polyfill";
import Hls from "hls.js";
import { t } from "i18n-js";
import { isFunction, uniqueId } from "lodash";
import type { PlyrEvent } from "plyr";
import Plyr from "plyr";
import { MediaElement } from "./MediaElement";
import { useHls } from "./useHlsPlyr";
import "./plyr.scss";

export interface MediaPlayerProps {
  autoplay?: boolean;
  captionsUrl?: string;
  contentType: string;
  downloadUrl?: string;
  isDownloadable?: boolean;
  onTimeUpdate?: (currentTime: number) => void;
  onEnded?: () => void;
  playerRef?: any;
  poster?: string;
  src: string;
  startTime: number;
  metadata?: any;
  preload?: "none" | "metadata" | "auto";
  transcoder?: "default" | "mux";
  menuPosition?: "top" | "bottom";
  onProgress?: (event: PlyrEvent) => void;
}

export const MediaPlayer = memo(
  ({
    captionsUrl,
    contentType,
    downloadUrl,
    isDownloadable = false,
    onTimeUpdate,
    onEnded,
    playerRef = null,
    poster,
    src,
    startTime,
    metadata,
    preload = "none",
    transcoder = "default",
    menuPosition = "top",
    onProgress,
  }: MediaPlayerProps) => {
    const [renderKey, setRenderKey] = useState(uniqueId("plyr-container-"));
    const playerId = useRef(uniqueId("plyr-"));
    const plyrRef = useRef<Plyr | null>(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const isHlsSupported = Hls.isSupported();
    const isHls = src.endsWith(".m3u8");
    const isHlsEnabled = isHls && isHlsSupported;
    const isHlsAutoStartLoadEnabled = preload !== "none";

    const { quality, startLoad } = useHls({
      id: playerId.current,
      renderKey,
      src,
      enabled: isHlsEnabled,
      autoStartLoadEnabled: isHlsAutoStartLoadEnabled,
      metadata,
      transcoder,
    });

    const playerOptions = useMemo(
      () => ({
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          ...(captionsUrl ? ["captions"] : []),
          "settings",
          "pip",
          "fullscreen",
          ...(isDownloadable ? ["download"] : []),
        ],
        urls: {
          download: downloadUrl,
        },
        ...(quality ? { quality } : {}),
      }),
      [captionsUrl, downloadUrl, quality, isDownloadable],
    );

    useEffect(
      () => () => {
        plyrRef.current?.destroy();
        setRenderKey(uniqueId("plyr-container-"));
      },
      [playerOptions],
    );

    useEffect(() => {
      plyrRef.current = new Plyr(`#${playerId.current}`, playerOptions);
      if (playerRef) {
        playerRef.current = {
          plyr: plyrRef.current,
        };
      }
    }, [playerOptions, renderKey, playerRef]);

    const setStartTime = () => {
      if (plyrRef.current) {
        plyrRef.current.currentTime = startTime;
      }
    };

    const resetVideoProgress = () => {
      if (plyrRef.current && isFunction(onEnded)) {
        onEnded();
      }
    };

    // Set the start time of the video on load
    useEffect(() => {
      if (startTime && plyrRef.current) {
        plyrRef.current.once("canplay", setStartTime);
      }

      if (plyrRef.current) {
        plyrRef.current.once("ended", resetVideoProgress);
        if (isFunction(onProgress)) {
          plyrRef.current.on("timeupdate", onProgress);
        }
      }

      return () => {
        plyrRef.current?.off("canplay", setStartTime);
        if (isFunction(onProgress)) {
          plyrRef.current?.off("timeupdate", onProgress);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps -- plyrRef.current is necessary here since it may change
    }, [plyrRef.current]);

    useEffect(() => {
      if (startTime && plyrRef.current) {
        plyrRef.current.once("play", setStartTime);
      }

      return () => {
        plyrRef.current?.off("play", setStartTime);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps -- plyrRef.current is necessary here since it may change
    }, [startTime, plyrRef.current]);

    useEffect(() => {
      if (!plyrRef.current || !isHlsEnabled || isHlsAutoStartLoadEnabled) {
        return;
      }

      plyrRef.current.once("play", startLoad);

      return () => plyrRef.current?.off("play", startLoad);
    }, [renderKey, isHlsEnabled, isHlsAutoStartLoadEnabled, startLoad]);

    useEffect(() => {
      const handleOnTimeUpdate = () => {
        if (plyrRef.current && onTimeUpdate) {
          onTimeUpdate(plyrRef.current.currentTime);
        }
      };

      if (onTimeUpdate) {
        plyrRef.current?.on("timeupdate", handleOnTimeUpdate);
      }

      return () => {
        if (onTimeUpdate) {
          plyrRef.current?.off("timeupdate", handleOnTimeUpdate);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps -- plyrRef.current is necessary here since it may change
    }, [onTimeUpdate, plyrRef.current]);

    useEffect(() => {
      if (plyrRef.current) {
        const handleEnterFullscreen = () => setIsFullscreen(true);
        const handleExitFullscreen = () => setIsFullscreen(false);

        plyrRef.current.on("enterfullscreen", handleEnterFullscreen);
        plyrRef.current.on("exitfullscreen", handleExitFullscreen);

        return () => {
          plyrRef.current?.off("enterfullscreen", handleEnterFullscreen);
          plyrRef.current?.off("exitfullscreen", handleExitFullscreen);
        };
      }
      return undefined;
    }, [plyrRef.current]);

    return (
      <div
        key={renderKey}
        className={classNames("plyr-container", {
          "plyr-container--fullscreen": isFullscreen,
          "plyr-container__menu--bottom":
            menuPosition === "bottom" && !isFullscreen,
        })}
      >
        <MediaElement
          id={playerId.current}
          contentType={contentType}
          poster={poster}
          preload={preload}
        >
          {!isHlsEnabled && <source src={src} type={contentType} />}
          {captionsUrl && (
            <track
              kind="captions"
              label={t("default")}
              src={captionsUrl}
              srcLang="en"
            />
          )}
        </MediaElement>
      </div>
    );
  },
);

MediaPlayer.displayName = "MediaPlayer";
