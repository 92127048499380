import { useCallback } from "react";
import { t, toHumanSize } from "i18n-js";
import { noop } from "lodash";
import { useDropzone } from "react-dropzone";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Icon } from "@circle-react/components/shared/Icon";
import { Button } from "@circle-react/components/shared/uikit/Button";
import { useToast } from "@circle-react/components/shared/uikit/ToastV2";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { usePunditUserContext } from "@circle-react/contexts";
import { EmbedForm } from "@circle-react-shared/uikit/TipTapBlockEditor/EmbedExtension/EmbedForm";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Tab } from "@circle-react-uikit/TabV2";

export const FileUploadModalComponent = ({
  editor,
  range = { from: 0, to: 0 },
  addToLocalSgidToObjectMap = noop,
  type = "file",
  maxFileUploadSize = 4 * 1024 * 1024 * 1024,
}: any) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const modal = useModal();
  const toast = useToast();

  const { tiptap_video_transcoding_enabled: isTiptapVideoTranscodingEnabled } =
    currentCommunitySettings || {};

  const handleFileRejection = useCallback(
    errors => {
      const error = errors[0];
      if (error.errors[0].code === "too-many-files") {
        toast.error(t("tiptap.one_file_limit"));
      }
      if (error.errors[0].code === "file-too-large") {
        toast.error(
          t("tiptap.file_too_large", {
            size: toHumanSize(maxFileUploadSize),
          }),
        );
      }
    },
    [toast],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted: files => {
      editor.chain().focus().deleteRange(range).run();
      files.forEach(file => {
        editor
          .chain()
          .focus()
          .insertContent({
            type: "file-loader",
            attrs: {
              file,
            },
          })
          .run();
      });
      modal.remove();
    },
    onDropRejected: handleFileRejection,
    noClick: true,
    multiple: true,
    maxSize: maxFileUploadSize,
  });

  const uploadDescription =
    type === "video" && isTiptapVideoTranscodingEnabled
      ? t("tiptap.drag_and_drop_any_video")
      : t(`tiptap.drag_and_drop_${type}`);

  return (
    <Modal isOpen={modal.visible} onClose={modal.remove}>
      <Modal.Overlay />
      <Modal.Content size="lg">
        <Modal.Header>
          <Modal.Title size="sm">{t(`tiptap.attach_${type}`)}</Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={modal.remove} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="!px-5 md:!px-8">
          <Tab.Group className="overflow-hidden">
            {type == "video" && (
              <Tab.List className="border-primary border-b">
                <Tab>{t("tiptap.upload")}</Tab>
                <Tab>{t("tiptap.embed_link")}</Tab>
              </Tab.List>
            )}
            <Tab.Panels>
              <Tab.Panel>
                <div
                  className="border-primary mb-6 mt-2 flex min-h-[220px] flex-col items-center justify-center rounded-md border border-dotted"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <Icon
                    size={42}
                    className="h-[42px] !w-[42px]"
                    type="new-file-plus"
                  />
                  <div className="text-dark my-4">
                    <Typography.LabelSm weight="semibold">
                      {uploadDescription}
                    </Typography.LabelSm>
                  </div>
                  <Button type="button" onClick={open} variant="circle">
                    {t(`tiptap.choose_${type}`)}
                  </Button>
                </div>
              </Tab.Panel>
              <Tab.Panel className="px-px py-5">
                <EmbedForm
                  addToLocalSgidToObjectMap={addToLocalSgidToObjectMap}
                  editor={editor}
                  range={range}
                  provider={t(
                    "tiptap.extensions.embed.provider_description.top_video_providers",
                  )}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const FileUploadModal = ModalManager.create(
  ({
    editor,
    range,
    addToLocalInlineAttachments,
    addToLocalSgidToObjectMap,
    type,
    maxSize,
  }: any) => (
    <FileUploadModalComponent
      editor={editor}
      range={range}
      type={type}
      addToLocalInlineAttachments={addToLocalInlineAttachments}
      addToLocalSgidToObjectMap={addToLocalSgidToObjectMap}
      maxSize={maxSize}
    />
  ),
);

export const useFileUploadModal = () => useModal(FileUploadModal);
