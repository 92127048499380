/* eslint-disable no-restricted-syntax -- Not translatable strings */
import { useEffect, useRef } from "react";
import type { Editor } from "@tiptap/react";
import { t } from "i18n-js";
import { noop } from "lodash";
import { TextSelectDropdown } from "@circle-react-shared/TextSelectDropdown";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { useTextSource } from "@circle-react-uikit/InsertVariableButton/useTextSource";
import { IconButton } from "../HeaderV3/IconButton";

interface Variable {
  group: string;
  label: string;
  value: string;
}
const memberGroupLabel = t("dynamic_vars_groups.member");

export const DEFAULT_VARIABLES: Variable[] = [
  {
    group: memberGroupLabel,
    label: "{{member.first_name}}",
    value: "{{member.first_name}}",
  },
  {
    group: memberGroupLabel,
    label: "{{member.last_name}}",
    value: "{{member.last_name}}",
  },
  {
    group: memberGroupLabel,
    label: "{{member.name}}",
    value: "{{member.name}}",
  },
  {
    group: memberGroupLabel,
    label: "{{member.email}}",
    value: "{{member.email}}",
  },
  {
    group: memberGroupLabel,
    label: "{{member.public_uid}}",
    value: "{{member.public_uid}}",
  },
];

const DROPDOWN_TRIGGER_INPUT_TEXT = "{{";

export const InsertVariableButton = ({
  className,
  editor,
  inputRef,
  setInputChangeHandler = noop,
  variables = DEFAULT_VARIABLES,
  tooltipPlacement = "bottom",
  wrapperClassName = "max-w-sm pt-3",
  optionsClassName,
}: {
  className: string;
  editor?: Editor;
  inputRef?: React.RefObject<HTMLInputElement>;
  setInputChangeHandler?: (handler: () => void) => void;
  variables?: Variable[];
  tooltipPlacement?: "top" | "bottom";
  wrapperClassName?: string;
  optionsClassName?: string;
}) => {
  const textSource = useTextSource({ editor, inputRef });
  const getLastTwoChars = () => {
    if (!textSource) {
      return "";
    }

    const textUpToAnchor = textSource.textUpToAnchor();

    if (textUpToAnchor.length >= 2) {
      return textUpToAnchor.slice(-2);
    }

    return textUpToAnchor;
  };

  const handleInsertVariable = (variableName: string) => {
    const shouldSliceVariableName =
      getLastTwoChars() === DROPDOWN_TRIGGER_INPUT_TEXT;
    const textToInsert = shouldSliceVariableName
      ? variableName.slice(2)
      : variableName;
    textSource?.insertText(textToInsert);
  };

  const insertVariableButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleContentChange = () => {
      const shouldShowDmOptions =
        getLastTwoChars() === DROPDOWN_TRIGGER_INPUT_TEXT;
      if (shouldShowDmOptions && insertVariableButtonRef.current) {
        insertVariableButtonRef.current.click();
      }
    };

    if (editor) {
      editor.on("update", handleContentChange);
    } else {
      setInputChangeHandler(handleContentChange);
    }

    return () => {
      if (editor) {
        editor.off("update", handleContentChange);
      } else {
        setInputChangeHandler(noop);
      }
    };
  }, [textSource]);

  return (
    <div className={wrapperClassName}>
      <TextSelectDropdown
        options={variables}
        groupBy={option => option.group}
        onSelect={handleInsertVariable}
        buttonRef={insertVariableButtonRef}
        optionsClassName={optionsClassName}
      >
        <TippyV2
          placement={tooltipPlacement}
          delay={300}
          content={t("tiptap.add_variable")}
          interactive={false}
        >
          <IconButton
            iconSize={20}
            buttonSize={32}
            name="20-variables"
            className={className}
            ariaLabel={t("tiptap.add_variable")}
          />
        </TippyV2>
      </TextSelectDropdown>
    </div>
  );
};
