import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

const renderAvatarContainer = index => (
  <div key={index} className="py-1.5">
    <AvatarContainer md full>
      <Avatar />
      <RowContainer full>
        <Row md />
        <Row md full />
      </RowContainer>
    </AvatarContainer>
  </div>
);

const ChatThreadsTitleLoader = () => (
  <div className="mb-4">
    <RowContainer full>
      <Row md />
      <Row md half />
    </RowContainer>
  </div>
);
export const ChatThreadsListHeader = () => (
  <>
    <RowContainer lg full>
      <div className="bg-primary border-primary mb-2 rounded-lg border p-5">
        <ChatThreadsTitleLoader />
        {Array.from(Array(4).keys()).map(renderAvatarContainer)}
      </div>
    </RowContainer>
    <RowContainer lg full>
      <div className="bg-primary border-primary mb-2 rounded-lg border p-5">
        <ChatThreadsTitleLoader />
        {Array.from(Array(3).keys()).map(renderAvatarContainer)}
      </div>
    </RowContainer>
  </>
);
