import PropTypes from "prop-types";
import {
  SortableContext as DnDSortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortableListData } from "./SortableListDataProvider";

export const SortableContext = ({ id, items, children }) => {
  const {
    items: allItems,
    lockedVerticalAxis,
    lockedHorizontalAxis,
  } = useSortableListData();

  let strategy;
  if (lockedVerticalAxis) {
    strategy = verticalListSortingStrategy;
  } else if (lockedHorizontalAxis) {
    strategy = horizontalListSortingStrategy;
  }

  return (
    <DnDSortableContext id={id} items={items ?? allItems} strategy={strategy}>
      {children}
    </DnDSortableContext>
  );
};

SortableContext.propTypes = {
  id: PropTypes.string,
  /** Pass in items to SortableList in case of multiple containers */
  items: PropTypes.array,
  children: PropTypes.node.isRequired,
};
