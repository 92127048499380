import PropTypes from "prop-types";
import { times } from "lodash";
import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const FeedView = ({ count = 1 }) => (
  <>
    {times(count, index => (
      <div
        key={index}
        className="skeleton-loader skeleton-loader--feed-view !bg-primary !border-primary"
      >
        <AvatarContainer lg>
          <Avatar sm />
          <RowContainer>
            <Row sm />
          </RowContainer>
        </AvatarContainer>
        <div className="skeleton-loader--feed-view__body">
          <RowContainer md>
            <Row md full />
            <Row md full />
            <Row md almostFull />
          </RowContainer>
        </div>
        <RowContainer inline>
          <Row lg short />
          <Row lg short />
          <Row lg short />
        </RowContainer>
      </div>
    ))}
  </>
);

FeedView.propTypes = {
  count: PropTypes.number,
};
