import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const EventPopover = () => (
  <div className="w-full p-6">
    <RowContainer full>
      <Row lg short className="!h-6" isDark />
    </RowContainer>
    <div className="mt-6 flex justify-start gap-4">
      <Row md long className="!w-32" isDark />
      <Row md long className="!w-32" isDark />
    </div>
    <div className="mt-6 flex justify-between gap-4">
      <Row xxl full isDark />
      <Row xxl full isDark />
    </div>
  </div>
);
