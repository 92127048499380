import type { ReactNode } from "react";
import { Menu } from "@headlessui/react";
import type { LinkProps } from "react-router-dom";
import { ItemLink } from "./ItemLink";

interface ItemWithLinkProps {
  children: ReactNode;
  active?: boolean;
  onClick?: (event: any) => void;
  to?: LinkProps["to"];
  hasDanger?: boolean;
  linkType?: "button" | "submit" | "reset";
  as?: "a" | "button";
}

export const ItemWithLink = ({
  children,
  active: isActiveProp,
  onClick,
  to,
  as,
  ...rest
}: ItemWithLinkProps) => (
  <Menu.Item as={as} onClick={onClick}>
    {({ active }) => (
      <ItemLink to={to} active={active || isActiveProp} {...rest}>
        {children}
      </ItemLink>
    )}
  </Menu.Item>
);
