import PropTypes from "prop-types";
import classnames from "classnames";
import { useBEMClasses } from "@circle-react/hooks/utils/useBEMClasses";

export const Row = ({ className = "", isDark = false, ...props }) => {
  const blockElement = classnames("skeleton-loader__row", className);
  const { modifiers } = useBEMClasses(blockElement, props);

  return (
    <div
      className={classnames(blockElement, modifiers, {
        "!bg-tertiary": !isDark,
        "!bg-overlay-dark": isDark,
      })}
    />
  );
};

Row.propTypes = {
  // Width & Height
  badge: PropTypes.bool,
  square: PropTypes.bool,
  squareLarge: PropTypes.bool,
  squareExtraLarge: PropTypes.bool,
  // Width
  short: PropTypes.bool,
  long: PropTypes.bool,
  extraLong: PropTypes.bool,
  half: PropTypes.bool,
  almostFull: PropTypes.bool,
  full: PropTypes.bool,
  // Height
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  xxl: PropTypes.bool,
  x2l: PropTypes.bool,
  className: PropTypes.string,
  isDark: PropTypes.bool,
};
