import { Loader } from "@circle-react-uikit/Loader";
import { Rail } from "@circle-react-uikit/Rail";
import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const MemberList = () => (
  <Rail>
    <Rail.Header>
      <Row xxl full />
    </Rail.Header>
    <Rail.Content>
      <div className="h-full overflow-auto">
        <div className="px-2">
          <RowContainer lg full>
            {Array.from(Array(10).keys()).map(index => (
              <div key={index}>
                <AvatarContainer md full>
                  <Avatar />
                  <RowContainer full>
                    <Row lg almostFull />
                  </RowContainer>
                </AvatarContainer>
              </div>
            ))}
          </RowContainer>
        </div>
      </div>
    </Rail.Content>
  </Rail>
);

export const Profile = () => (
  <Rail>
    <Rail.Header>
      <Row xxl full />
    </Rail.Header>
    <Rail.Content>
      <div className="h-full overflow-auto">
        <div className="mb-16">
          <RowContainer lg full>
            <AvatarContainer lg full>
              <Avatar lg />
              <RowContainer full>
                <Row lg half />
                <Row lg almostFull />
                <div className="pt-4">
                  <AvatarContainer lg full>
                    <Row xxl long />
                    <Row xxl long />
                  </AvatarContainer>
                </div>
              </RowContainer>
            </AvatarContainer>
          </RowContainer>
        </div>
        <RowContainer full>
          <Row lg almostFull />
          <Row lg full />
          <Row lg full />
          <Row lg half />
        </RowContainer>
      </div>
    </Rail.Content>
  </Rail>
);

export const RailBar = () => (
  <Rail>
    <Rail.Header>
      <Row xxl full />
    </Rail.Header>
    <Rail.Content>
      <Loader center />
    </Rail.Content>
  </Rail>
);
